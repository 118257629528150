.cas {
  &_more {
    @apply
      p-0 max-h-0 pointer-events-none overflow-hidden -z-1
      flex flex-col lg:flex-row gap-16 justify-between
    ;

    .cass_holder.active & {
      @apply
        pointer-events-auto max-h-[9999px] z-1
        lg:p-16
      ; 
    }
  }


  &_about {
    @apply
      w-full lg:max-w-[480px] bg-[#F6F9FE]
      rounded-8 p-16
      flex flex-col gap-8
    ;
  }

  &_pc {
    @apply
      lg:max-w-[240px] w-full
      flex flex-col gap-12 justify-between
    ;

    &_item {
      @apply
        bg-[#F6F9FE] rounded-8 p-12
        flex flex-col gap-12
      ;

      header {
        border-bottom: 1px solid #D8E5FA;
        @apply
          flex flex-row gap-12 items-center pb-6 
        ;
      }

      ul {
        @apply
          flex flex-col gap-8
        ;

        li {
          @apply
            pl-18 relative
          ;

          &:before {
            content: '';
            @apply
              absolute left-0
              w-10
              bg-cover bg-no-repeat
            ;
          }
        }
      }
    }
  }

  &_pros {
    li {
      &:before {
        background-image: url('../images/svg/pros_item.svg');
        @apply
          h-10 top-6
        ;
      }
    }
  }

  &_cons {
    li {
      &:before {
        background-image: url('../images/svg/cons_item.svg');
        @apply
          h-2 top-10
        ;
      }
    }
  }

  &_info {
    @apply
      lg:max-w-[380px] w-full
      rounded-8 overflow-hidden bg-[#F6F9FE]
    ;

    header {
      @apply
        bg-[#2D3064] text-white px-12
      ;
    }

    footer {
      @apply
        bg-[#F6F9FE] p-8
        grid grid-cols-1 lg:grid-cols-2 gap-8
      ;
    }

    &_item {
      @apply
        bg-white px-8
      ;

      li {
        @apply
          flex flex-row flex-wrap gap-4 items-center justify-between 
          py-8 text-12 text-[#425466]
        ;
      }

      li + li {
        border-top: 1px solid rgba(66, 84, 102, .12)
        ;
      }

      label {
        @apply
          flex flex-row gap-4 items-center
        ;
      }
    }
  }

  &_btnMore {
    @apply
      relative pl-32
      text-14 font-m text-[#2D3064]/60
    ;

    div {
      @apply
        absolute top-0 left-0
        w-24 h-24 bg-[#D1DDF0] rounded-full
      ;

      &:before, &:after {
        content: '';
        @apply
          absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%]
          w-14 h-2 bg-white
        ;
      }

      &:after {
        @apply
          rotate-90
        ;
      }
    }

    &.active {
      div {
        &:after {
          @apply
            rotate-0
          ;
        }
      }
    }

    &_D {
      @apply
        hidden lg:block
      ;
    }

    &_M {
      @apply
        lg:hidden
      ;
    }
  }
}